//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// ref: https://colors.eva.design/
// $primary: #7570ea; // Yutt, buying.in.th
// $primary: #85bb46; // Yutt, get.in.th
// $primary: #7349a0; // Yutt, listing.in.th
// $primary: #ee9c3d; // Yutt, renting.in.th
// $primary: #2074ac; // Yutt, selling.in.th
// $primary: #365081; // Yutt, used.in.th
// $primary: #d2ad4c; // Yutt, wealth.in.th
$primary: #2265DE; // Yutt, urod
